import React from 'react'

function Home() {
    return (
        <div className="home" data-aos="fade-up">
            <section className="hero">
                <div className="hero-left">
                    <h2>Hello, 👋 I am</h2>
                    <h1>Khaled Saifullah</h1>
                    {/* <p>&lt; Frontend Developer ⁄ &gt;</p> */}
                    <p className="tag">Bringing ideas to life.</p>
                    <p className="desc">I’ve always been passionate about pixels and design projects and haven’t stopped working and learning about new technologies. My main focus is coding the website mobile-friendly, SEO friendly, browser compatible and maximum optimized way so that it performs well. I’m grateful that my job allows me to work from anywhere.</p>
                    <a href="#about" className="a-link"><span className="base-color">&lt;</span> Explore More /<span className="base-color">&gt;</span></a>
                </div>
                <div className="hero-right">
                    <h2 data-text="Code is Poetry">Code is Poetry</h2>
                </div>
            </section>
        </div>
    )
}

export default Home
