import React from 'react'

export const WorkList = ({ url, src, title }) => {
    return (
        <div className="project k-hover">
            <a href={url} target="blank">
                <div className="details">
                    <img src={src} alt="" />
                    <p>{title}</p>
                </div>
            </a>
        </div>
    )
}

export const AddWorkList = ({ title, details, langs, github, link }) => {
    const techlang = langs.map(lang => <li>{lang}</li>)
    return (
        <div className="project-info">
            <div className="top">
                <i class="far fa-bookmark"></i>
                <span>{title}</span>
            </div>
            <div className="details">
                <p>{details}</p>
                <ul>
                    {techlang}
                </ul>
            </div>
            <div className="project-link k-hover">
                <a href={github} target="blank"><i class="fab fa-github"></i></a>
                <a href={link} target="blank"><i class="fas fa-external-link-alt"></i></a>
            </div>
        </div>
    )
}
