import React from 'react'
import { AddWorkList, WorkList } from './WorkList'

const Work = () => {
    return (
        <>
            <h2 className="heading" data-text="my" id="work">Work</h2>
            <div className="work" id="#work" data-aos="fade-up"  data-aos-easing="ease-in-out">
                <WorkList url="https://patrioteco.com" src="./project/patriot.png" title="(Garments)" />
                <WorkList url="https://layogroup.net" src="./project/layogroup.png" title="(Apparel Manufacturing)" />
                <WorkList url="https://ottawaleggings.ca" src="./project/ottawale.png" title="(Ecommerce)" />
                <WorkList url="https://nxtbase.io" src="./project/nxt.png" title="(Augmented Reality)" />
                <WorkList url="https://mbosuk.com" src="./project/mbosuk.png" title="(Ecommerce)" />
                {/* <WorkList url="https://tazexim.com" src="./project/tazexim.png" title="(Ecommerce)" /> */}
                {/* <WorkList url="https://martglow.com" src="./project/martglow.png" title="(Ecommerce)" /> */}
                {/* <WorkList url="https://wolvesbd.com" src="./project/wolvesbd.png" title="(Graphics Agency)" /> */}
                <WorkList url="https://nutilbd.com" src="./project/nutil.png" title="(Construction Company)" />
            </div>

            <h2 className="heading" data-text="work">Additional</h2>
            <div className="additional-project" data-aos="fade-up" >
                <AddWorkList title="Tip Calculator" details="A simple tip calculator where people can canculate" langs={['Javascript', 'JQuery']} github="https://github.com/ksnirob/tip-calculator" link="https://ksnirob.github.io/tip-calculator/" />
                <AddWorkList title="ToDo List" details="Clean Web todo list manager which store data on browser " langs={['CSS', 'Javascript']} github="https://github.com/ksnirob/todo-list" link="https://ksnirob.github.io/todo-list/" />
                <AddWorkList title="Tasbeeh Web App" details="Tasbeeh App can count & track the dhikr on the screen " langs={['React', 'CSS']} github="https://github.com/ksnirob/tasbeeh" link="https://tasbeeeh.netlify.app" />
                <AddWorkList title="Recipe Web App" details="Find food recipes by searching any keywords" langs={['HTML', 'CSS', 'Javascript']} github="https://github.com/ksnirob/recipe-web-app" link="https://ksnirob.github.io/recipe-web-app/" />
                <AddWorkList title="Task Reminder" details="Task reminder can store task with date and time" langs={['React', 'CSS']} github="https://github.com/ksnirob/TaskManager" link="https://taskmanager7.netlify.app/" />
                <AddWorkList title="Event Manager (inDev)" details="Campus event managemnets for institute authorities" langs={['CSS', 'React', 'Mysql']} github="https://github.com/ksnirob/uitsevents" />
            </div>
        </>
    )
}

export default Work
