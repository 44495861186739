import React from 'react'

const About = () => {
    return (
        <div className="about-section" id="about">
            <h2 className="heading" data-text="me">About</h2>
            <div className="about">
            <div className="left-side" data-aos="fade-up">
                    <div>
                        <h1 className="res-hidden">Coding with <br/>passion for Problem Solving</h1>
                        <h1 className="web-hidden">Coding with passion for Problem Solving</h1>
                    </div>
                    <div className="about-info">
                        <div className="pd">
                            <span className="number"><h1>06+</h1></span>
                            <span className="info">Years <br/> Experience</span>
                        </div>
                        <div className="pd">
                            <span className="number"><h1>43+</h1></span>
                            <span className="info">Completed <br/> Project</span>
                        </div>
                        <div className="pd">
                            <span className="number"><h1>03+</h1></span>
                            <span className="info">Companies <br/> Work</span>
                        </div>
                    </div>
                </div>
                <div className="right-side" data-aos="fade-up">
                    <p>I specialize in building complex web applications, leading front-end teams, digital product design and developing visual design systems. I enjoy creating effortless user experience and designing delightful digital products. The entire process of going from a concept to release and gathering user’s feedback on either client’s or my own products is what makes me wake up everyday!
                       I worked with numerous clients from all around the world from early startups to well-established companies. <br/><br/>Let’s create something awesome together!</p>
                </div>
            </div>
            <div className="pro-lang" data-aos="fade-up" data-aos-easing="ease-in-out">
                <img src="./technology/html.png" alt="" />
                <img src="./technology/css3.png" alt="" />
                <img src="./technology/javascript.png" alt="" />
                <img src="./technology/bootstrap.png" alt="" />
                <img src="./technology/mysql.png" alt="" />
                <img src="./technology/wordpress2.png" alt="" />
                <img src="./technology/react.png" alt="" />
                <img src="./technology/nodejs1.png" alt="" />
                <img src="./technology/express2.png" alt="" />
                <img src="./technology/mongodb2.png" alt="" />
            </div>
        </div>
    )
}

export default About
