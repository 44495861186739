import React from 'react'
import { useState } from 'react';

function Experience() {
    const [toggle, setToggle] = useState(1);

    const toggleTab = (index) => {
        setToggle(index)
    }
    return (
        <>
        <h2 className="heading" data-text="my" id="experience">Experience</h2>
        <section className="qualification" id="#experiance" data-aos="fade-up">
                <div className="qualification-container">
                    <div className="qualification-tabs">
                        <div className={toggle === 1 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(1)}>
                        <i class="fas fa-graduation-cap"></i>
                        Work
                        </div>

                        <div className={toggle === 2 ? "tabs active-tabs" : "tabs"} onClick={() => toggleTab(2)}>
                        <i class="fas fa-briefcase"></i>
                        Education
                        </div>
                    </div>

                    <div className="qualification-sections">
                        <div className={toggle === 1 ? "content active-content" : "content"}>
                            <div className="data">
                                <div>
                                    <div className="title">Web Developer</div>
                                    <span className="subtitle">Hashtag Visual</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2020 - Present
                                    </div> */}
                                </div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>
                            </div>

                            <div className="data">
                                <div></div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>

                                <div>
                                    <div className="title">Frontend Developer </div>
                                    <span className="subtitle">Nxtbase</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2020 - 2021
                                    </div> */}
                                </div>
                            </div>

                            <div className="data">
                                <div>
                                    <div className="title">Trainer</div>
                                    <span className="subtitle">Hashtag Devs</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2021 - Present
                                    </div> */}
                                </div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>
                            </div>

                            <div className="data">
                                <div></div>

                                <div>
                                    <span className="rounder"></span>
                                    {/* <span className="line"></span> */}
                                </div>

                                <div>
                                    <div className="title">Freelancer</div>
                                    <span className="subtitle">Fiverr</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2018 - 2020
                                    </div> */}
                                </div>
                            </div>
                        </div>

                        <div className={toggle === 2 ? "content active-content" : "content"}>
                            <div className="data">
                                <div>
                                    <div className="title">Bsc in CSE</div>
                                    <span className="subtitle">UITS - Dhaka</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2018 - 2021
                                    </div> */}
                                </div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>
                            </div>

                            <div className="data">
                                <div></div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>

                                <div>
                                    <div className="title">Frontend (Web)</div>
                                    <span className="subtitle">w3schools</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2012 - 2014
                                    </div> */}
                                </div>
                            </div>

                            <div className="data">
                                <div>
                                    <div className="title">Backend (Web)</div>
                                    <span className="subtitle">TWLP</span>
                                    {/* <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2016 - 2017
                                    </div> */}
                                </div>

                                <div>
                                    <span className="rounder"></span>
                                    <span className="line"></span>
                                </div>
                            </div>

                            <div className="data">
                                <div></div>

                                <div>
                                    <span className="rounder"></span>
                                    {/* <span className="line"></span> */}
                                </div>

                                {/* <div>
                                    <div className="title">Computer Engineer</div>
                                    <span className="subtitle">Dhaka - UITS</span>
                                    <div className="date">
                                    <i class="far fa-calendar-check"></i>
                                    2018 - 2021
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="center">
                <a href="/Resume.pdf" className="a-link" target="blank"><span className="base-color">&lt;</span> Get Resume /<span className="base-color">&gt;</span></a>
                </div>
            </section>
            </>
            
    )
}

export default Experience
