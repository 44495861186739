import React from 'react'
import Headroom from 'react-headroom'
import { HashLink as Link } from 'react-router-hash-link'

const Header = () => {

    window.addEventListener('scroll', function(){
        const header = document.querySelector("header")
        header.classList.toggle("sticky", window.scrollY > 0)
    })


    // const toggle = document.getElementById('toggle')
    // const navBar = document.getElementById('navBar')

    const toggleClick = () =>{
        const toggle = document.getElementById('toggle')
        const navBar = document.getElementById('navBar')

        toggle.classList.toggle('active')
        navBar.classList.toggle('active')
    }

    document.toggleClick = e => {
        if(e.target.id !== 'toggle' && e.target.id !== 'navBar'){
            const toggle = document.getElementById('toggle')
            const navBar = document.getElementById('navBar')
            toggle.classList.remove('active')
            navBar.classList.remove('active')
        }
    }


    


    const scrollWithOffset = (el) => {
        const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
        const yOffset = -80; 
        window.scrollTo({ top: yCoordinate + yOffset, behavior: 'smooth' }); 
    }

    return (
        <Headroom>
            <header className="header">
                <div className="container">
                    <div className="row">
                        <div className="logo">
                            <h1>Khaled</h1>
                        </div>
                        <input type="checkbox" id="nav-check" />
                        <label for="nav-check" id="toggle" onClick={toggleClick}>
                            {/* <i className="fas fa-bars"></i> */}
                            <span></span>
                            <span></span>
                            <span></span>
                        </label>
                        <nav id="navBar">
                            <ul>
                                <li><Link to="#about" scroll={el => scrollWithOffset(el)}>About</Link></li>
                                <li><Link to="#experience" scroll={el => scrollWithOffset(el)}>Experience</Link></li>
                                <li><Link to="#work" scroll={el => scrollWithOffset(el)}>Work</Link></li>
                                <li><Link to="#contact" scroll={el => scrollWithOffset(el)}>Contact</Link></li>
                                <li><a href="https://github.com/ksnirob" target="blank"><i class="fab fa-github"></i></a></li>
                                <li><a href="https://www.linkedin.com/in/ksnirob" target="blank"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </header>
        </Headroom>
    )
}

export default Header
