import Header from "./components/Header";
import Home from "./components/Home";
import About from "./components/About";
import Experience from "./components/Experience";
import Work from "./components/Work";
import Footer from "./components/Footer";
import { BrowserRouter as Router} from "react-router-dom";

import AOS from 'aos';
import 'aos/dist/aos.css';

// Cursor

let cursor = document.querySelector('.cursor')
let outerCursor = document.querySelector('.outer-cursor')

document.addEventListener('mousemove', showCursor)

function showCursor(e){
  cursor.style.left = e.clientX + 'px'
  cursor.style.top = e.clientY + 'px'
  outerCursor.style.left = e.clientX + 'px'
  outerCursor.style.top = e.clientY + 'px'
}

let linkHover = Array.from(document.querySelectorAll("a"));
console.log(linkHover);
linkHover.forEach(link => {
  link.addEventListener('mouseover', () => {
    outerCursor.classList.add('grow')
  })

  link.addEventListener('mouseleave', () => {
    outerCursor.classList.remove('grow')
  })
})

// Scroll Animation
AOS.init({
  offset: 200,
  duration: 1000
});

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Home />
        <About />
        <Experience />
        <Work />
        <Footer />
      </div>
    </Router>
  );
}

export default App;
