import React from 'react'

const Footer = () => {
    return (
        <div>
            <div className="footer" data-aos="fade-up"id="contact">
                <h2>Whats Next</h2>
                <h1>Let's Work Together</h1>
                <p>
                    If you'd like to talk about a project, my inbox is always open. Whether you have a question or just want to say hi, I’ll try my best to get back to you!
                </p>
                <a href="mailto:hello@ksnirob.info">Say Hello</a>
            </div>

            <div className='call-btn'>
                <a href="tel:+8801830681074">
                <div className='button'>
                    <div className='icon'><i class="fa fa-phone" aria-hidden="true"></i></div>
                    <span>15 mins FREE Consultation</span>
                </div>
                </a>
            </div>
            <div className="build-by">
                <h1>Designed & Built by Me .&#41;</h1>
                <div>
                    <a href="https://github.com/ksnirob" target="blank"><i class="fab fa-github"></i></a>
                    <a href="https://www.linkedin.com/in/ksnirob" target="blank"><i class="fab fa-linkedin-in"></i></a>
                </div>
            </div>
        </div>
    )
}

export default Footer
